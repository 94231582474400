import React, { useRef, useState, useEffect } from 'react';
import AudioProgressBar from '../components/alafasy/AudioProgressBar';
import VolumeInput from '../components/player/VolumeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlayCircle,
    faPauseCircle,
    faVolumeUp,
    faVolumeOff,
    faForwardStep,
    faBackwardStep
} from "@fortawesome/free-solid-svg-icons";

const PlayerComponent = ({src, songCount, songIndex, onNext, onPrev}) => {
    const theme = localStorage.getItem("theme");
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false); 
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [buffered, setBuffered] = useState(0); 
    const [volume, setVolume] = useState(1); 

    useEffect(() => {
        const audio = audioRef.current;

        if (audio) {
            const updateTime = () => setCurrentTime(audio.currentTime);
            const setAudioDuration = () => setDuration(audio.duration);
            const updateBuffered = () => {
                if (audio.buffered.length > 0) {
                    setBuffered(audio.buffered.end(audio.buffered.length - 1));
                }
            };

            audio.addEventListener('timeupdate', updateTime);
            audio.addEventListener('loadedmetadata', setAudioDuration);
            audio.addEventListener('progress', updateBuffered);

            return () => {
                audio.removeEventListener('timeupdate', updateTime);
                audio.removeEventListener('loadedmetadata', setAudioDuration);
                audio.removeEventListener('progress', updateBuffered);
            };
        }
    }, []);

    const togglePlayPause = () => {
        const audio = audioRef.current;

        if (audio) {
            if (isPlaying) {
                audio.pause();
            } else {
                audio.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleMuteUnmute = () => {
        const audio = audioRef.current;
        if (audio) {
            if (audio.volume === 0) {
                audio.volume = volume; 
            } else {
                setVolume(audio.volume);
                audio.volume = 0;
            }
        }
    };

    const handleVolumeChange = (newVolume) => {
        const audio = audioRef.current;
        if (audio) {
            audio.volume = newVolume;
            setVolume(newVolume);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleProgressChange = (value) => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = value;
            setCurrentTime(value);
        }
    };

    return (
        <div >
            <div className="controls-right-section">
                <div className="controls-right-section--timer flex justify-between" style={{justifyContent:'space-between'}}>
                    <div className="controls-right-section--timer-progress">
                        {formatTime(currentTime)}
                    </div>
                    <div className="controls-right-section--timer-duration">
                        {formatTime(duration)}
                    </div>
                </div>
            </div>
            <div className="progress-bar-container">
                <AudioProgressBar
                    duration={duration}
                    currentProgress={currentTime}
                    buffered={buffered}
                    onChange={(e) => {
                        if (!audioRef.current) return;
                        audioRef.current.currentTime = e.currentTarget.valueAsNumber;
                        handleProgressChange(e.currentTarget.valueAsNumber);
                    }}
                />
                <audio ref={audioRef} src={src} />
            </div>
            <div className="player-controls-section">
                <div className="player-controls-section--wrapper">
                    <div className="w-36"></div>
                    <div className="controls-main">
                        <div className="controls-main--wrapper">
                            <FontAwesomeIcon
                                icon={faBackwardStep}
                                className={
                                    theme === "light" ?
                                        songIndex === 0
                                            ? "icon-backward-disabled"
                                            : "icon-backward" : songIndex === 0
                                            ? "icon-backward-disabled-dark"
                                            : "icon-backward-dark"
                                }
                                onClick={songIndex === 0 ? null : onPrev}
                            />
                            {isPlaying ? (
                                <FontAwesomeIcon
                                    icon={faPauseCircle}
                                    className="icon-circle"
                                    onClick={togglePlayPause}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faPlayCircle}
                                    className="icon-circle"
                                    onClick={togglePlayPause}
                                />
                            )}
                            <FontAwesomeIcon
                                icon={faForwardStep}
                                className={
                                    theme === "light" ?
                                        songIndex === songCount - 1
                                            ? "icon-forward-disabled"
                                            : "icon-forward" : songIndex === songCount - 1 ? "icon-forward-disabled-dark" : "icon-forward-dark"
                                }
                                onClick={songIndex === songCount - 1 ? null : onNext}
                            />
                        </div>
                    </div>
                    <div className="controls-right-section--right">
                        {volume === 0 ? (
                            <FontAwesomeIcon
                                icon={faVolumeOff}
                                className={theme === "light" ? "icon-volume" : "icon-volume-dark"}
                                onClick={handleMuteUnmute}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faVolumeUp}
                                className={theme === "light" ? "icon-volume" : "icon-volume-dark"}
                                onClick={handleMuteUnmute}
                            />
                        )}
                        <p>
                            <VolumeInput
                                volume={volume}
                                onVolumeChange={handleVolumeChange}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerComponent;
