import React from "react";
import { graphql } from "gatsby";
import Alltranslations from "../components/AlltranslationsComp";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PlayerPage from "../templates/playerPage";

const SurahComponent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <PlayerPage />
     
    </>
  );
};

export default SurahComponent;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        featuredPostCount
        postPerPage
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { published: { ne: false } }
        fileAbsolutePath: { regex: "/content/posts/" }
      }
    ) {
      nodes {
        ...PostQueryFragment
      }
    }
  }
`;
