import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout2 from "../components/Layout2";
import '../assets/scss/components/_playerPage.scss';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AudioPlayerContext from "../util/audioPlayerContext";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import PopupDownloadLink from "../components/PopupDownloadLink";
import PlayerComponent from "../templates/playerComponent";
import Seo from "../components/Seo";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby"; // Import navigate function


const PlayerPage = (props) => {
  const audioCtx = useContext(AudioPlayerContext);
  const [surahPage, setSurah] = useState();
  const [allReciters, setAllReciters] = useState();
  const [songCount, setSongCount] = useState();
  const [songIndex, setsongIndex] = useState();
  const [authorPage, setAuthorPage] = useState(0);
  const { t, i18n } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allAuthorReciterJson {
        edges {
          node {
            title
            description
            featured
            image {
              id
              absolutePath
              relativePath
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 400)
              }
            }
          }
        }
      }
      allAllRecitersJson {
        nodes {
          title
          metaDescription
          downloadAll
          private
          metaTitle
          reciters {
            trackName
            trackURL
            downloadLink
            surahNo
          }
        }
      }
        allAllTranslationsJson {
      nodes {
        title
        metaDescription
        downloadAll
        private
        metaTitle
        reciters {
          trackName
          trackURL
          downloadLink
          surahNo
        }
      }
    }
      allAuthorTranslationsJson {
        edges {
          node {
            title
            description
            featured
            image {
              id
              absolutePath
              relativePath
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 400)
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  let url = '';
if (typeof window !== 'undefined') {
  url = window.location.pathname; // Get the current route
}

  const location = useLocation().pathname;
  var reciter;
  var surah;
  const parts = location.split('/');
  var typeRoute = parts[parts.length - 3]

  if (url.endsWith("/")) {
    typeRoute = parts[parts.length - 4];
  } else {
    typeRoute = parts[parts.length - 3]
  }

  if (typeRoute === 'reciter') {
    reciter = data.allAuthorReciterJson.edges;
    surah = data.allAllRecitersJson.nodes;
  } else {
    reciter = data.allAuthorTranslationsJson.edges;
    surah = data.allAllTranslationsJson.nodes;
  }
  const handleDownloadClick = (event) => {
    event.stopPropagation();
  };
 
  let surahRoute, authorRoute;

  if (url.endsWith("/")) {
    // Si termina con "/", usa índices normales
    surahRoute = parts[parts.length - 2];
    authorRoute = parts[parts.length - 3];
  } else {
    // Si no termina con "/", ajusta los índices
    surahRoute = parts[parts.length - 1];
    authorRoute = parts[parts.length - 2];
  }
  useEffect(() => {
    audioCtx.setPlaying(false);
    audioCtx.setSongIndex();
    console.log(location.split('/'), 'surahsurahsurahsuraha')
    const authorMatching = reciter.find(item =>
      item.node.title?.toLowerCase().replace(/\s+/g, '-') === authorRoute?.toLowerCase()
    );
    const surahMatching = surah.find(item =>
      item.title?.toLowerCase().replace(/\s+/g, '-') === authorRoute?.toLowerCase()
    );
    const surahSet = surahMatching.reciters.find(item =>
      item.trackName?.toLowerCase().replace(/\s+/g, '-') === surahRoute?.toLowerCase()
    );
    const surahIndex = surahMatching.reciters.findIndex(item =>
      item.trackName?.toLowerCase().replace(/\s+/g, '-') === surahRoute?.toLowerCase()
    );
    setAllReciters(surahMatching.reciters)
    setSongCount(surahMatching.reciters.length)
    setsongIndex(surahIndex)
    setSurah(surahSet)
    console.log(surahIndex, 'surahMatchinglength')
    setAuthorPage(authorMatching?.node);
  }, [location, reciter]);




  const onNext = () => {
    if (songIndex + 1 < songCount) {
      const newReciterSlug = allReciters[songIndex + 1].trackName.toLowerCase().replace(/\s+/g, '-');
      const locationParts = location.split('/');
  
      // Replace only the last segment with the new slug
      if (locationParts[locationParts.length - 1] === '') {
        // If the URL ends with a trailing slash, adjust the index
        locationParts[locationParts.length - 2] = newReciterSlug;
      } else {
        locationParts[locationParts.length - 1] = newReciterSlug;
      }
  
      const newSlug = locationParts.join('/');
      navigate(newSlug); // Navigate to the new URL
    }
  };
  
  const onPrev = () => {
    if (songIndex - 1 >= 0) {
      const newReciterSlug = allReciters[songIndex - 1].trackName.toLowerCase().replace(/\s+/g, '-');
      const locationParts = location.split('/');
  
      // Replace only the last segment with the new slug
      if (locationParts[locationParts.length - 1] === '') {
        // If the URL ends with a trailing slash, adjust the index
        locationParts[locationParts.length - 2] = newReciterSlug;
      } else {
        locationParts[locationParts.length - 1] = newReciterSlug;
      }
  
      const newSlug = locationParts.join('/');
      navigate(newSlug); // Navigate to the new URL
    }
  };
  

  console.log(`https://staging.quranplayermp3.com/ar/${typeRoute}/${authorRoute}/${surahRoute}/`, 'jjkljkjlkjljkkjljk')
  return (
    <Layout2>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        {/* Add hrefLang attribute in the head */}
        <link rel="alternate" hrefLang="en" href={`https://staging.quranplayermp3.com/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ar" href={`https://staging.quranplayermp3.com/ar/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="fr" href={`https://staging.quranplayermp3.com/fr/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="de" href={`https://staging.quranplayermp3.com/de/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="tr" href={`https://staging.quranplayermp3.com/tr/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="es" href={`https://staging.quranplayermp3.com/es/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="zh" href={`https://staging.quranplayermp3.com/zh/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ur" href={`https://staging.quranplayermp3.com/ur/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ru" href={`https://staging.quranplayermp3.com/ru/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="pt" href={`https://staging.quranplayermp3.com/pt/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="it" href={`https://staging.quranplayermp3.com/it/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="id" href={`https://staging.quranplayermp3.com/id/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="bn" href={`https://staging.quranplayermp3.com/bn/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="nl" href={`https://staging.quranplayermp3.com/nl/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="hi" href={`https://staging.quranplayermp3.com/hi/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="fa" href={`https://staging.quranplayermp3.com/fa/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ug" href={`https://staging.quranplayermp3.com/ug/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="so" href={`https://staging.quranplayermp3.com/so/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="sw" href={`https://staging.quranplayermp3.com/sw/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="no" href={`https://staging.quranplayermp3.com/no/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="da" href={`https://staging.quranplayermp3.com/da/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="se" href={`https://staging.quranplayermp3.com/se/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="ha" href={`https://staging.quranplayermp3.com/ha/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="bs" href={`https://staging.quranplayermp3.com/bs/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="th" href={`https://staging.quranplayermp3.com/th/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="tg" href={`https://staging.quranplayermp3.com/tg/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="tl" href={`https://staging.quranplayermp3.com/tl/${typeRoute}/${authorRoute}/${surahRoute}/`} />
        <link rel="alternate" hrefLang="x-default" href={`https://staging.quranplayermp3.com/${typeRoute}${authorRoute}/${surahRoute}/`} />
      </Helmet>
      <Seo
        description={`${t(surahPage?.trackName)} | ${t(authorPage?.title)} – ${t('Enhance your Quranic experience with high-quality Quran (Koran) MP3 recitations. Stream, listen, or download your favorite recitations today.')}`}
        title={`${t(surahPage?.trackName)} | ${t(authorPage?.title)}`}
      />
      <div className="main playerContainer">
        <Link href={location.includes('reciter') ? '/all-reciters' : '/all-translations'} className="player-back-line">
          &lt;
          <div className="text">{t('Back')}</div>
        </Link>
        <div className="playerContMain">
          <div>
            <h1 className="playerTitle">{t(surahPage?.trackName)} | {t(authorPage?.title)}</h1>
          </div>
          <div className="player-data">
            <div className="player-data-info">
              {authorPage?.image?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  image={getImage(authorPage.image.childImageSharp.gatsbyImageData)}
                  alt="banner"
                  layout="constrained"
                />
              )}
              <div className="player-data-text">
                <h2 className="player-data-text-title">{t(surahPage?.trackName)}</h2>
                <p className="player-data-text-text">
                  {t(surahPage?.trackName)} | {t(authorPage?.title)} – {t('Enhance your Quranic experience with high-quality Quran (Koran) MP3 recitations. Stream, listen, or download your favorite recitations today.')}
                </p>
              </div>
            </div>

            {surahPage?.downloadLink ? (
              <>
                <PlayerComponent onNext={onNext} onPrev={onPrev} songIndex={songIndex} songCount={songCount} src={surahPage.downloadLink} />
                <PopupDownloadLink downloadLink={surahPage?.downloadLink}>
                  <button
                    className="player-dowload-btn"
                    onClick={handleDownloadClick}
                  >
                    {t("Download")}
                  </button>
                </PopupDownloadLink>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default PlayerPage;
